<template>
  <el-container class="layout-container">
    <Asides :role="userRole" :permissions="userPermissions"/>
    <el-container class="flex-center">
      <Headers/>
      <el-scrollbar ref="layoutDefaultsScrollbarRef">
        <Mains/>
      </el-scrollbar>
    </el-container>
  </el-container>
</template>
<script>
import Asides from '@/layout/component/aside';
import Headers from '@/layout/component/header';
import Mains from '@/layout/component/main';

export default {
  name: 'layoutDefaults',
  components: {Asides, Headers, Mains},
  data() {
    return {
      userRole: '',
      userPermissions: []
    };
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        if (this.$refs.layoutDefaultsScrollbarRef) {
          this.$refs.layoutDefaultsScrollbarRef.wrap.scrollTop = 0;
        }
        //this.fetchUserData();
      });
    }
  },
  methods: {
    fetchUserData() {

    }
  },
  created() {
    this.fetchUserData();
  }
}
</script>
<style scoped lang="scss">
.layout-container {
  height: 100vh;
  display: flex;
}
</style>

const themeConfigModule = {
    namespaced: true,
    state: {
        themeConfig: {
            isDraw: false,
            primary: '#409eff',
            menuBgColor: '#282c34',
            themeStyle: 'theme-1',
            // 默认顶栏导航背景颜色
            topBar: '#ffffff',
            // 默认顶栏导航字体颜色
            topBarColor: '#606266',
            // 默认菜单导航背景颜色
            menuBar: '#282c34',
            // 默认菜单导航字体颜色
            menuBarColor: '#eaeaea',
            // 默认分栏菜单背景颜色
            columnsMenuBar: '#282c34',
            // 默认分栏菜单字体颜色
            columnsMenuBarColor: '#e6e6e6',
            animation: 'opacitys',
            // 是否开启菜单水平折叠效果
            isCollapse: false,
            // 是否开启菜单手风琴效果
            isUniqueOpened: true,
            // 是否开启固定 Header
            isFixedHeader: true,
            // 是否开启 Tags view
            isTagsView: false,

            layout: 'defaults',

        }
    },
    mutations: {
        // 设置布局配置
        getThemeConfig(state, data) {
            state.themeConfig = data;
        },
    },
    actions: {
        setThemeConfig({commit}, data) {
            commit('getThemeConfig', data);
        },
    }
}

export default themeConfigModule;
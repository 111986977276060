export default {
    namespaced: true,
    state: {
        activePath: '',
        sider: [],
    },
    mutations: {
        setActivePath(state, path) {
            state.activePath = path;
        },
        setSider(state, menu) {
            state.sider = menu;
        },
    },
}
import axios from 'axios';
import {Message} from 'element-ui';
import {getCookies, removeCookies} from '@/libs/util';
import Setting from '@/setting';
import router from '@/router';

const service = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 10000, // 请求超时时间
});

axios.defaults.withCredentials = true; // 携带cookie

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        config.baseURL = Setting.apiBaseURL;
        if (config.file) config.headers['Content-Type'] = 'multipart/form-data';
        const token = getCookies('token');
        if (token) config.headers['Authorization'] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        let obj = {};
        if (response.data != null) {
            if (typeof response.data == 'string') {
                obj = JSON.parse(response.data);
            } else {
                obj = response.data;
            }
        }
        const code = response.data ? obj.code : 0;

        switch (code) {
            case 0:
                return obj;
            case 1:
                return obj;
            case -1:
                return Promise.reject(typeof obj.msg === 'object'
                    ? obj.msg[Object.keys(obj.msg)[0]][0]
                    : obj.msg || {msg: '未知错误'});
            case 110003:
            case 110004:
                localStorage.clear();
                removeCookies('token');
                removeCookies('expires_time');
                removeCookies('uuid');
                router.replace({name: 'login'});
                break;
            case 110005:
            case 110006:
            case 110008:
                router.replace({name: 'system_opendir_login'});
                break;
            default:
                return Promise.reject(obj || {msg: '未知错误'});
        }
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            removeCookies('token');
            removeCookies('expires_time');
            removeCookies('uuid')
            router.replace({name: 'login'});
            return Promise.reject(error);
        }
        Message.error(error.msg);
        return Promise.reject(error);
    },
);

export default service;

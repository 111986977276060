<template>
  <div class="layout-footer mt15">
    <div class="layout-footer-warp">
      <div class="ivu-global-footer i-copyright">
        <div class="ivu-global-footer-links" v-if="!copyright">
          <a :href="item.href" target="_blank" v-for="(item, index) in links" :key="index">{{ item.title }}</a>
        </div>
        <div class="ivu-global-footer-copyright" v-if="copyright">{{ copyright }}</div>
        <div class="ivu-global-footer-copyright" v-else>
          Copyright © 2014-2023
          <a href="https://www.crmeb.com" target="_blank">{{ version }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'layoutFooter',
  data() {
    return {
      links: [],
      copyright: '',
      version: '',
    };
  },
};
</script>

<style scoped lang="scss">
.layout-footer {
  width: 100%;
  display: flex;

  &-warp {
    margin: auto;
    color: var(--prev-color-text-secondary);
    text-align: center;
  }
}

.ivu-global-footer {
  margin: 5px 0;
  text-align: center;
  box-sizing: border-box;
}

.i-copyright {
  flex: 0 0 auto;
}

.ivu-global-footer-links {
  margin-bottom: 5px;
}

.ivu-global-footer-links a:not(:last-child) {
  margin-right: 40px;
}

.ivu-global-footer-links a {
  font-size: 14px;
  color: #808695;
  transition: all 0.2s ease-in-out;
}

.ivu-global-footer-copyright {
  color: #808695;
  font-size: 14px;
}

.ivu-global-footer-copyright a {
  color: #808695;
}
</style>

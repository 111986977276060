
function getMenusName() {
    let storage = window.localStorage;
    let menuList = JSON.parse(storage.getItem('menuList'));
    if (typeof menuList !== 'object' || menuList === null) {
        menuList = [];
    }
    return menuList;
}
export default {
    namespaced: true,
    state: {
        menusName: getMenusName(),

    },
}
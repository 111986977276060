<template>
  <div class="layout-navBars-breadcrumb-index">
    <Breadcrumb/>
    <User/>
  </div>
</template>

<script>
import Breadcrumb from '@/layout/navBars/breadcrumb/breadcrumb.vue';
import User from '@/layout/navBars/breadcrumb/user.vue';
export default {
  name: 'layoutNavBars',
  components: {Breadcrumb,User},
}
</script>

<style scoped lang="scss">
.layout-navBars-breadcrumb-index {
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: var(--prev-bg-topBar);
  border-bottom: 1px solid var(--prev-border-color-lighter);
}
</style>
import request from '@/libs/request'

/**
 * 通用配置-加载数据
 * @returns {*}
 */
export function systemsShow() {
    return request({
        url: '/v1/web/systems/show',
        method: 'get'
    })
}

/**
 * 设置协议内容
 * @returns {*}
 */
export function systemsAgreement(data) {
    return request({
        url: '/v1/web/systems/agreement',
        method: 'post',

        data
    })
}

/**
 * 通用配置-设置全局
 * @returns {*}
 */
export function systemsSetting(data) {
    return request({
        url: '/v1/web/systems/setting',
        method: 'post',

        data
    })
}

/**
 * 通用配置-短信
 * @returns {*}
 */
export function systemsSms(data) {
    return request({
        url: '/v1/web/systems/sms',
        method: 'post',
        data
    })
}

/**
 * 通用配置-云存储
 * @returns {*}
 */
export function systemsCloudStorage(data) {
    return request({
        url: '/v1/web/systems/cloud_storage',
        method: 'post',
        data
    })
}

/**
 * 通用配置-内容安全
 * @returns {*}
 */
export function systemsContentSafe(data) {
    return request.post('/v1/web/systems/content_safe', data, {headers: {'Content-Type': 'multipart/form-data '}})
}

/**
 * 通用配置-第三方服务
 * @returns {*}
 */
export function systemsThirdApi(data) {
    return request({
        url: '/v1/web/systems/third_api',
        method: 'post',
        data
    })
}


/**
 * 通用配置-图片上传
 * @returns {*}
 */
export function filesUpload(data) {
    return request.post('/v1/web/files/upload',
        data,
        {headers: {'Content-Type': 'multipart/form-data '}})
}

/**
 * 通用配置-图片上传-七牛云
 * @returns {*}
 */
export function filesUploadQiniu(type) {
    return request({
        url: '/v1/qiniu/token?type=' + type,
        method: 'get'
    })
}


<template>
  <el-header class="layout-header" :height="setHeaderHeight">
    <div class="layout-navBars-container">
      <BreadcrumbIndex/>
    </div>
  </el-header>
</template>

<script>
import BreadcrumbIndex from '@/layout/navBars/breadcrumb/';

export default {
  name: 'layoutHeader',
  components: {BreadcrumbIndex},
  data() {
    return {};
  },
  computed: {
    setHeaderHeight() {
      let {isTagsView, layout} = this.$store.state.themeConfig.themeConfig;
      if (isTagsView && layout !== 'classic') return '84px';
      else return '50px';
    },
  },
};
</script>

<style lang="scss">
.layout-navBars-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
</style>
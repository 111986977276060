<template>
  <div class="layout-navBars-breadcrumb">
    <i
        class="layout-navBars-breadcrumb-icon"
        :class="getThemeConfig.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        @click="onThemeConfigChange"
    ></i>
  </div>
</template>
<script>
export default {
  name: 'layoutBreadcrumb',
  data() {
    return {
      breadcrumbList: [],
      routeSplit: [],
      routeSplitFirst: '',
      routeSplitIndex: 1,
    };
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
  },
  methods: {
    onThemeConfigChange() {
      this.$store.state.themeConfig.themeConfig.isCollapse = !this.$store.state.themeConfig.themeConfig.isCollapse;
    },
  }
}
</script>
<style scoped lang="scss">
.layout-navBars-breadcrumb {
  // flex: 1;
  height: inherit;
  display: flex;
  align-items: center;
  padding-left: 15px;

  .layout-navBars-breadcrumb-icon {
    cursor: pointer;
    font-size: 18px;
    margin-right: 15px;
    color: var(--prev-bg-topBarColor);
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .layout-navBars-breadcrumb-span {
    opacity: 0.7;
    color: var(--prev-bg-topBarColor);
  }

  .layout-navBars-breadcrumb-iconfont {
    font-size: 14px;
    margin-right: 5px;
  }
}
</style>

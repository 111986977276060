import LayoutMain from '@/layout';
import setting from '@/setting';

let routePre = setting.routePre;

const meta = {
    auth: true,
};

const pre = 'dashboard_';

export default {
    path: routePre + '/dashboard',
    name: 'dashboard',
    header: 'dashboard',
    redirect: {
        name: `${pre}workplace`,
    },
    meta,
    component: LayoutMain,
    children: [{
        path: 'ranking/:id?',
        name: `${pre}ranking`,
        meta: {
            title: '排行榜',
            auth: true,
        },
        component: () => import('@/pages/dashboard/ranking/index'),
    }, {
        path: 'stage/:id?',
        name: `${pre}stage`,
        meta: {
            title: '创建活动',
            auth: ['stages.store', 'stages.update'],
        },
        component: () => import('@/pages/dashboard/stage/index'),
    }, {
        path: 'workplace',
        name: `${pre}workplace`,
        meta: {
            title: '工作台',
            auth: true
        },
        component: () => import('@/pages/dashboard/workplace'),
    }, {
        path: 'recovery',
        name: `${pre}recovery`,
        header: 'recovery',
        meta: {
            title: '回收站',
            isAffix: true,
        },
        component: () => import('@/pages/dashboard/recovery'),
    }, {
        path: 'player/:id?',
        name: `${pre}player`,
        header: 'player',
        meta: {
            title: '选手管理',
            isAffix: true,
        },
        component: () => import('@/pages/dashboard/player'),
    }, {
        path: 'player/create/:id?',
        name: `${pre}player_create`,
        meta: {
            title: '添加选手',
            isAffix: true,
        },
        component: () => import('@/pages/dashboard/player/create'),
    }, {
        path: 'player/:id?/detail/:player_id?',
        name: `${pre}player_detail`,
        meta: {
            title: '查看选手',
            isAffix: true,
        },
        component: () => import('@/pages/dashboard/player/detail'),
    }, {
        path: 'financial/management/:id?',
        name: `financialManagement`,
        meta: {
            title: '财务管理',
            auth: ['assets.stages.*']
        },
        component: () => import('@/pages/dashboard/financial/management'),
    }, {
        path: 'financial',
        name: `${pre}financial`,
        meta: {
            title: '财务中心',
            auth: ['assets.index']
        },
        component: () => import('@/pages/dashboard/financial'),
    }, {
        path: 'themes',
        name: `${pre}themes`,
        meta: {
            title: '主题中心',
            auth: ['themes.index', 'themes.market']
        },
        component: () => import('@/pages/dashboard/theme'),
    }, {
        path: 'activity/log',
        name: `activityLog`,
        meta: {
            title: '活动日志',
            auth: ['stages.log.index']
        },
        component: () => import('@/pages/dashboard/activity'),
    }, {
        path: 'fans',
        name: `${pre}fans`,
        meta: {
            title: '粉丝管理',
            auth: ['fans.index']
        },
        component: () => import('@/pages/dashboard/fans'),
    }, {
        path: 'complaint',
        name: `${pre}complaint`,
        meta: {
            title: '投诉建议',
            auth: ['complaint.index']
        },
        component: () => import('@/pages/dashboard/complaint'),
    },
        {
            path: 'devise',
            name: `${pre}devise`,
            meta: {
                title: '主题管理'
            },
            component: () => import('@/pages/dashboard/devise/index'),
        },
        {
            path: 'devise/diy',
            name: `${pre}devise_diy`,
            meta: {
                title: '主题编辑'
            },
            component: () => import('@/pages/dashboard/devise/diy'),
        }, {
            path: 'comment',
            name: `${pre}comment`,
            meta: {
                title: '评论管理'
            },
            component: () => import('@/pages/dashboard/comment/index'),
        }
    ],
};

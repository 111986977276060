<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'App',
  watch: {
    $route(to) {
      const onRoutes = to.meta.activeMenu ? to.meta.activeMenu : to.path;
      this.$store.commit('menu/setActivePath', onRoutes);
    },
  },
  methods: {
    ...mapMutations('media', ['setDevice']),
    handleMatchMedia() {
      const matchMedia = window.matchMedia;
      if (matchMedia('(max-width: 600px)').matches) {
        document.documentElement.style.fontSize = 'calc(100vw/7.5)';
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    },
  },
  mounted() {
    this.handleMatchMedia();
  }

}
</script>

<style lang="stylus">
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
}

.ivu-modal-wrap ::v-deep .connect_customerServer_img {
  display: none;
}

.right-box .ivu-color-picker .ivu-select-dropdown {
  position: absolute;
  left: -73px !important;
}


.fileBox {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #c0ccda;
  line-height: 50px;
  text-align: center;
  color: #303133;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
}

.fc-files {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size 30px
}

.form_content_width {
  width: 100%;
  max-width: 235px;
}

.form_item_input {
  width: 100%;
  max-width 414px
}


.content_width_196 {
  width: 100%;
  max-width: 196px;
}

.section {
  padding-top: 25px;
  border-bottom: 1px dashed #eeeeee;

  &-hd {
    padding-left: 10px;
    margin-bottom: 20px
    border-left: 3px solid var(--prev-color-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
}

.section:first-child {
  padding: 0;
}
</style>
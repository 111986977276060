import Cookies from 'js-cookie'
import config from '@/config'
import {objEqual} from '@/libs/tools';
import { cloneDeep } from 'lodash';
const {title} = config

export const setCookies = (key, val) => {
    Cookies.set(key, val);
};
export const getCookies = (key) => {
    return Cookies.get(key)
}

export const removeCookies = (key) => {
    return Cookies.remove(key)
}

export const localRead = (key) => {
    return localStorage.getItem(key) || '';
};

export const setTitle = (routeItem, vm) => {
    const handledRoute = getRouteTitleHandled(routeItem)
    const pageTitle = showTitle(handledRoute, vm)
    let pageTitleCooke = getCookies('pageTitle')
    let title1 = pageTitleCooke === undefined ? title : pageTitleCooke;
    window.document.title = pageTitle ? `${title1} - ${pageTitle}` : title1
}
export const getRouteTitleHandled = (route) => {
    let router = {...route}
    let meta = {...route.meta}
    let title = ''
    if (meta.title) {
        if (typeof meta.title === 'function') {
            meta.__titleIsFunction__ = true
            title = meta.title(router)
        } else title = meta.title
    }
    meta.title = title
    router.meta = meta
    return router
}
export const showTitle = (item) => {
    let {title} = item.meta
    if (!title) return
    title = (item.meta && item.meta.title) || item.name
    return title
}

export function getMenuopen(to, menuList) {
    const allMenus = [];
    menuList.forEach((menu) => {
        const menus = transMenu(menu, []);
        allMenus.push({
            path: menu.path,
            openNames: [],
        });
        menus.forEach((item) => allMenus.push(item));
    });
    const currentMenu = allMenus.find((item) => item.path === to.path);
    return currentMenu ? currentMenu.openNames : [];
}

function transMenu(menu, openNames) {
    if (menu.children && menu.children.length) {
        const itemOpenNames = openNames.concat([menu.path]);
        return menu.children.reduce((all, item) => {
            all.push({
                path: item.path,
                openNames: itemOpenNames,
            });
            const foundChildren = transMenu(item, itemOpenNames);
            return all.concat(foundChildren);
        }, []);
    } else {
        return [menu].map((item) => {
            return {
                path: item.path,
                openNames: openNames,
            };
        });
    }
}

export const R = (menuList, newOpenMenus) => {
    menuList.forEach((item) => {
        let newMenu = {};
        for (let i in item) {
            if (i !== 'children') newMenu[i] = cloneDeep(item[i]);
        }
        newOpenMenus.push(newMenu);
        item.children && R(item.children, newOpenMenus);
    });
    return newOpenMenus;
};

/**
 * @returns {Array}
 * @param route
 * @param homeRoute
 */
export const getBreadCrumbList = (route, homeRoute) => {
    let homeItem = {...homeRoute, icon: homeRoute.meta?.icon};
    let routeMatched = route.matched;
    if (routeMatched.some((item) => item.name === homeRoute.name)) return [homeItem];
    let res = routeMatched
        .filter((item) => {
            return item.meta === undefined || !item.meta.hideInBread;
        })
        .map((item) => {
            let meta = {...item.meta};
            if (meta.title && typeof meta.title === 'function') {
                meta.__titleIsFunction__ = true;
                meta.title = meta.title(route);
            }
            return {
                icon: (item.meta && item.meta.icon) || '',
                name: item.name,
                meta: meta,
            };
        });
    res = res.filter((item) => {
        return !item.meta.hideInMenu;
    });
    return [{...homeItem, to: homeRoute.path}, ...res];
};

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = (list) => {
    localStorage.tagNaveList = JSON.stringify(list);
};

export const localSave = (key, value) => {
    localStorage.setItem(key, value);
};

/**
 * @param {Array} routers 路由列表数组
 * @param homeName
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName = 'home') => {
    let i = -1;
    let len = routers.length;
    let homeRoute = {};
    while (++i < len) {
        let item = routers[i];
        if (item.children && item.children.length) {
            let res = getHomeRoute(item.children, homeName);
            if (res.name) return res;
        } else {
            if (item.name === homeName) homeRoute = item;
        }
    }
    return homeRoute;
};

/**
 * @param {Array} list 标签列表
 * @param route
 */
export const getNextRoute = (list, route) => {
    let res;
    if (list.length === 2) {
        res = getHomeRoute(list);
    } else {
        const index = list.findIndex((item) => routeEqual(item, route));
        if (index === list.length - 1) res = list[list.length - 2];
        else res = list[index + 1];
    }
    return res;
};

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
    const params1 = route1.params || {};
    const params2 = route2.params || {};
    const query1 = route1.query || {};
    const query2 = route2.query || {};
    return route1.name === route2.name && objEqual(params1, params2) && objEqual(query1, query2);
};
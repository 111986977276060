import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import media from './module/media';
import userInfo from './module/userInfo';
import themeConfig from './module/themeConfig';
import routesList from './module/routesList';
import menu from './module/menu';
import menus from './module/menus';
import keepAliveNames from './module/keepAliveNames';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {
        //
    },
    plugins: [
        new VuexPersistence({
            reducer: (state) => ({
                media: state.media,
                userInfo: state.userInfo,
                themeConfig: state.themeConfig,
                routesList: state.routesList,
                keepAliveNames: state.keepAliveNames,
                menu: state.menu,
                menus:state.menus
            }),
            storage: window.localStorage,

        }).plugin,
    ],
    modules: {
        media,
        userInfo,
        themeConfig,
        menu,
        routesList,
        keepAliveNames,
        menus
    },
});

<template>
  <div class="layout-navBars-breadcrumb-user" style="flex: 1">
    <div class="layout-navBars-breadcrumb-user-icon" @click="refresh">
      <i class="el-icon-refresh-right"></i>
    </div>
    <el-dropdown :show-timeout="70" :hide-timeout="50" @command="onDropdownCommand">
      <span class="layout-navBars-breadcrumb-user-link">
        <img :src="getUserInfos.head_pic" class="layout-navBars-breadcrumb-user-link-photo mr5" alt=""/>
        {{ getUserInfos.account }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu>
        <el-dropdown-item command="user">账户设置</el-dropdown-item>
        <el-dropdown-item command="switch">切换公众号</el-dropdown-item>
<!--        <el-dropdown-item command="user2">清理文件缓存</el-dropdown-item>-->
<!--        <el-dropdown-item command="user2">清理数据缓存</el-dropdown-item>-->
        <el-dropdown-item divided command="logOut">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import {AccountLogout} from "@/api/account";
import {removeCookies} from "@/libs/util";

export default {
  name: 'layoutBreadcrumbUser',
  data() {
    return {
      getUserInfos: {}
    }
  },
  methods: {
    refresh() {
      this.bus.$emit('onTagsViewRefreshRouterView', this.$route.path);
    },
    onDropdownCommand(path) {
      if (path === 'logOut') {
        this.$confirm('您确定退出登录当前账户吗？打开的标签页和个人设置将会保存。', '退出登录确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          AccountLogout().then((res) => {
            this.$message.success(res.msg);
            localStorage.clear();
            removeCookies('token');
            removeCookies('user_id');
            this.$router.push({
              name: 'login'
            });
          }).catch((res) => {
            this.$message.error(res.msg);
          });
        }).catch(() => {

        });
      } else if (path === 'user') {
        this.$router.push({name: 'setting_account'});
      } else if (path === 'switch') {
        this.$router.push({name: 'setting_platform'});
      } else {
       // this.$router.push(path);
      }
    }
  }
}
</script>


<style scoped lang="scss">
.layout-navBars-breadcrumb-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &-photo {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
  }

  &-icon {
    padding: 0 10px;
    cursor: pointer;
    color: var(--prev-bg-topBarColor);
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;

    &:hover {
      background: var(--prev-color-hover);

      i {
        display: inline-block;
        animation: logoAnimation 0.3s ease-in-out;
      }
    }
  }

  & ::v-deep .el-dropdown {
    color: var(--prev-bg-topBarColor);
    cursor: pointer;
  }

  & ::v-deep .el-badge {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }

  & ::v-deep .el-badge__content.is-fixed {
    top: 12px;
  }
}

.layout-navBars-breadcrumb-user-link {
  padding-right: 20px;
}
</style>

<template>
  <el-main class="layout-main">
    <el-scrollbar
        class="layout-scrollbar"
        ref="layoutScrollbarRef"
        :style="{ minHeight: `calc(100vh - ${headerHeight}` }"
    >
      <LayoutParentView/>
      <Footers/>
    </el-scrollbar>
  </el-main>
</template>
<script>
import Footers from '@/layout/footer/index';
import LayoutParentView from '@/layout/routerView/parent.vue';

export default {
  name: 'layoutMain',
  components: {LayoutParentView, Footers},
  data() {
    return {headerHeight: '50px'}
  }
}
</script>
<style scoped lang="scss">

</style>
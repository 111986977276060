import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import Setting from '@/setting';
import {removeCookies, getCookies, setTitle} from '@/libs/util'
import {PrevLoading} from '@/utils/loading';
import {includeArray} from "@/libs/auth";
import store from "@/store";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
    routes,
    mode: Setting.routerMode,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((_) => _.meta.auth)) {
        const token = getCookies('token');
        if (token && token !== 'undefined') {
            const role = store.state.userInfo.role;
            if (role === 'founder') {
                next();
            } else {
                const access = store.state.userInfo.uniqueAuth;
                const isPermission = includeArray(to.meta.auth, access);
                if (isPermission) {
                    next();
                } else {
                    next({
                        name: "403",
                    });
                }
            }
        } else {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath,
                },
            });
            localStorage.clear();
            removeCookies('token');
            removeCookies('user_id');
        }
    } else {
        next();
    }
});
router.afterEach((to) => {
    setTitle(to, router.app);
    window.scrollTo(0, 0);
    PrevLoading.done();
});
export default router;
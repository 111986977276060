import request from '@/libs/request'

/**
 * 登录
 * @returns {*}
 */
export function accountLogin(data) {
    return request({
        url: '/v1/web/login',
        method: 'post',
        data
    })
}

/**
 * 登录
 * @returns {*}
 */
export function AccountLogout() {
    return request({
        url: '/v1/web/logout',
        method: 'get'
    })
}


/**
 * 获取验证码图片
 * @returns {*}
 */
export function captcha_pro() {
    return request({
        url: '/v1/captchas/phrase',
        method: 'get'
    })
}

/**
 * 初始化信息
 * @returns {*}
 */
export function initial() {
    return request({
        url: '/v1/web/initial',
        method: 'get'
    })
}

/**
 * 获取身份验证
 * @returns {*}
 * @param user_id
 */
export function getUsersShow(user_id) {
    return request({
        url: '/v1/web/users/show?user_id=' + user_id,
        method: 'get'
    })
}

/**
 * 获取身份验证
 * @returns {*}
 * @param data
 * @param user_id
 */
export function setUsersShow(data, user_id) {
    return request({
        url: '/v1/web/users?user_id=' + user_id,
        method: 'put',
        data
    })
}


<!-- aside.vue -->
<template>
  <el-aside class="layout-aside" :class="setCollapseWidth" v-if="clientWidth > 1000">
    <Logo/>
    <el-scrollbar class="flex-auto" ref="layoutAsideRef">
      <Vertical :menuList="filteredMenuList" :class="setCollapseWidth"/>
    </el-scrollbar>
  </el-aside>
  <el-drawer :visible.sync="getThemeConfig.isCollapse" :with-header="false" direction="ltr" size="180px" v-else>
    <el-aside class="layout-aside w100 h100">
      <Logo/>
      <el-scrollbar class="flex-auto" ref="layoutAsideRef">
        <Vertical :menuList="filteredMenuList"/>
      </el-scrollbar>
    </el-aside>
  </el-drawer>
</template>

<script>
import Logo from '@/layout/logo/index';
import Vertical from '@/layout/navMenu/vertical';
import {getUsersShow} from "@/api/account";
import {getCookies} from "@/libs/util";
import store from "@/store";
import {systemsShow} from "@/api/general";


export default {
  name: 'layoutAside',
  components: {Logo, Vertical},
  props: {
    role: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filteredMenuList: [],
      clientWidth: '',
      menuList: [
        {
          path: '/dashboard/stage',
          title: '添加活动',
          icon: 'circle-plus-outline',
          permissions: ['stages.store', 'stages.update'],
        },
        {
          path: '/dashboard',
          title: '基础',
          icon: 'menu',
          children: [
            {path: '/dashboard/workplace', title: '工作台'},
            {path: '/dashboard/financial', title: '财务中心', permissions: ['assets.index']},
            // {path: '/dashboard/themes', title: '主题中心', permissions: ['themes.index', 'themes.market']},
            {path: '/dashboard/devise', title: '主题管理'},
            {path: '/dashboard/activity/log', title: '活动日志', permissions: ['stages.log.index']},
            {path: '/dashboard/fans', title: '粉丝管理', permissions: ['fans.index']},
            {path: '/dashboard/complaint', title: '投诉建议', permissions: ['complaint.index']},
          ]
        },
        {
          path: '/plugins',
          title: '应用',
          icon: 's-platform',
          children: [
            {
              path: '/plugins/gifts',
              title: '礼物功能',
              permissions: ['gifts.store', 'gifts.update', 'gifts.status', 'gifts.paylog', 'gifts.destroy', 'gifts.paylog.export', 'gifts.copy'],
            },
            {path: '/plugins/bless', title: '祝福弹幕', permissions: ['bless.index'],},
            {path: '/plugins/captcha', title: '验证码', permissions: ['captcha.index'],},
            {path: '/plugins/insider', title: '内幕码', permissions: ['insiders.logs', 'insiders.setting'],},
          ]
        },
        {
          path: '/settings',
          title: '系统',
          icon: 'setting',
          children: [
            {path: '/settings/user', title: '用户管理', permissions: ['users.store', 'users.update', 'users.destroy']},
            {path: '/settings/weblogs', title: '操作日志'},
            {path: '/settings/platform', title: '平台管理', permissions: ['platforms.index']},
            {
              path: '/settings/general',
              title: '通用配置',
              permissions: ['systems.setting', 'systems.agreement', 'systems.sms', 'systems.cloud_storage', 'systems.content_safe', 'systems.third_api'],
            },
            {
              path: '/settings/domains',
              title: '系统配置',
              permissions: ['domains.batch.list', 'domains.check.list', 'domains.upload.file', 'domains.remote.url', 'domains.mysql.optimize']
            },
          ]
        },
      ],
    };
  },
  computed: {

    setCollapseWidth() {
      return this.$store.state.themeConfig.themeConfig.isCollapse ? ['layout-aside-width64'] : ['layout-aside-width-default'];
    },
    getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
  },
  created() {
    this.fetchUserPermissions();
    this.getSystemsShow();
    this.initMenuFixed(document.body.clientWidth);
    this.bus.$on('layoutMobileResize', (res) => {
      this.initMenuFixed(res.clientWidth);
    });
    this.bus.$on('updateElScrollBar', () => {
      setTimeout(() => {
        this.$refs.layoutAsideRef.update();
      }, 300);
    });
    this.bus.$on('routesListChange', () => {
      this.setFilterRoutes();
    });
  },
  methods: {
    getSystemsShow() {
      systemsShow().then(res => {
        this.$store.commit('userInfo/cloud_storage', res.data.cloud_storage.switch);
        this.$store.commit('userInfo/cloud_url', res.data.cloud_storage.qiniu.url);
      }).catch(() => {
      });
    },
    fetchUserPermissions() {
      getUsersShow(getCookies('user_id')).then(res => {
        this.$store.commit('userInfo/role', res.data.role);
        if (store.state.userInfo.role === 'founder') {
          this.filteredMenuList = this.menuList;
        } else {
          this.$store.commit('userInfo/uniqueAuth', res.data.permission !== false ? res.data.permission : []);
          let permissions = store.state.userInfo.uniqueAuth;
          permissions = permissions.filter(item => item !== '');

          // 过滤菜单，包括子菜单
          this.filteredMenuList = this.menuList.map(item => {
            // 复制当前项，避免直接修改原数据
            const clonedItem = {...item};

            // 处理有 children 的项
            if (clonedItem.children) {
              clonedItem.children = clonedItem.children.filter(child => {
                // 如果子项有 permissions 属性，则检查是否包含用户权限
                if (child.permissions) {
                  return child.permissions.some(childPermission => permissions.includes(childPermission));
                }
                // 如果子项只有 permission 属性，则检查是否包含用户权限
                else if (child.permission) {
                  return permissions.includes(child.permission);
                }
                // 如果没有权限相关的属性，则默认保留
                return true;
              });

              // 如果子菜单为空数组，则移除该子菜单（可选）
              if (clonedItem.children.length === 0) {
                delete clonedItem.children;
              }
            }

            // 如果当前项有 permission 属性，则检查是否包含用户权限
            if (clonedItem.permissions && !clonedItem.permissions.some(childPermission => permissions.includes(childPermission))) {

              // 如果不包含用户权限，则移除该项（可选）
              return false;
            }
            if (!clonedItem.permissions && !clonedItem.children) {
              return false;
            }

            // 返回处理后的项
            return clonedItem;
          }).filter(item => item);

          // 注意：我们不再在 filter 回调中直接修改 this.filteredMenuList
        }
      }).catch(() => {
        // 错误处理
      });
    },
    initMenuFixed(clientWidth) {
      this.clientWidth = clientWidth;
      this.$emit('routesListChange');
    },
    setFilterRoutes() {
      if (this.$store.state.themeConfig.themeConfig.layout === 'columns') return false;
      this.$store.commit('menus/childMenuList', this.filterRoutesFun(this.$store.state.routesList.routesList));
    },
    filterRoutesFun(arr) {
      return arr
          .filter((item) => item.path)
          .map((item) => {
            item = Object.assign({}, item);
            if (item.children) item.children = this.filterRoutesFun(item.children);
            return item;
          });
    },
    extractAndDeduplicate(e) {
      let extracted = e.map(item => {
        const permission = typeof item === 'string' ? item : '';
        const parts = permission.split('.');
        return parts.length > 0 ? parts[0] : '';
      });
      return [...new Set(extracted)];
    }
  },
  beforeDestroy() {
    this.bus.$off('routesListChange');
  },
  destroyed() {
    this.bus.$off('updateElScrollBar', () => {
    });
  },
}
</script>

<style scoped lang="scss">
.layout-aside {
  /* 样式定义 */
}
</style>

import LayoutMain from '@/layout';
import setting from '@/setting';

let routePre = setting.routePre;

const meta = {
    auth: true,
};

const pre = 'home_';

export default {
    path: routePre + '/plugins',
    name: 'plugins',
    header: 'plugins',
    redirect: {
        name: `${pre}plugins`,
    },
    meta,
    component: LayoutMain,
    children: [
        {
            path: 'gifts',
            name: `${pre}gifts`,
            header: 'gifts',
            meta: {
                title: '礼物功能',
                auth: ['gifts.store', 'gifts.update', 'gifts.status', 'gifts.paylog', 'gifts.destroy', 'gifts.copy']
            },
            component: () => import('@/pages/plugins/gifts'),
        },
        {
            path: 'gifts/history/:gift_id',
            name: `giftsHistory`,
            header: 'giftsHistory',
            meta: {
                title: '打赏记录',
                activeMenu: routePre + '/plugins/gifts',
                auth: ['gifts.paylog.export', 'gifts.paylog']
            },
            component: () => import('@/pages/plugins/gifts/history'),
        },
        {
            path: 'gifts/create',
            name: `giftCreate`,
            header: 'giftCreate',
            meta: {
                title: '新建礼物',
                activeMenu: routePre + '/plugins/gifts',
                auth: ['gifts.store', 'gifts.update']
            },
            component: () => import('@/pages/plugins/gifts/create'),
        },
        {
            path: 'bless',
            name: `${pre}bless`,
            header: 'bless',
            meta: {
                title: '祝福弹幕',
                auth: ['bless.index']
            },
            component: () => import('@/pages/plugins/bless'),
        },
        {
            path: 'captcha',
            name: `${pre}captcha`,
            header: 'captcha',
            meta: {
                title: '验证码',
                auth: ['captcha.index']
            },
            component: () => import('@/pages/plugins/captcha'),
        },
        {
            path: 'insider',
            name: `${pre}insider`,
            header: 'insider',
            meta: {
                title: '内部码',
                auth: ['insiders.logs', 'insiders.setting']
            },
            component: () => import('@/pages/plugins/insider'),
        }
    ],
};

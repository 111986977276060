export default {
    namespaced: true,
    state: {
        name: '',
        avatar: '',
        role: '',
        cloud_storage: '',
        cloud_url: '',
        uniqueAuth: [],
    },
    mutations: {
        name(state, name) {
            state.name = name;
        },
        avatar(state, avatar) {
            state.avatar = avatar;
        },
        role: (state, role) => {
            state.role = role;
        },
        cloud_storage: (state, cloud_storage) => {
            state.cloud_storage = cloud_storage;
        },
        cloud_url: (state, cloud_url) => {
            state.cloud_url = cloud_url;
        },
        uniqueAuth(state, uniqueAuth) {
            state.uniqueAuth = uniqueAuth;
        },
    },
}
import LayoutMain from '@/layout';
import settings from '@/setting';
import frameOut from './modules/frameOut'
import setting from './modules/setting';
import dashboard from './modules/dashboard';
import plugins from "@/router/modules/plugins";

let routePre = settings.routePre;
const frameIn = [
    {
        path: routePre,
        meta: {title: 'VOTE',},
        redirect: {name: 'dashboard_workplace',},
        component: LayoutMain,
    },
    setting,
    dashboard,
    plugins
];

const frameOuts = frameOut

const errorPage = [
    {
        path: routePre + '/403',
        name: '403',
        meta: {
            title: '403',
        },
        component: () => import('@/pages/system/error/403'),
    },
    {
        path: routePre + '/500',
        name: '500',
        meta: {
            title: '500',
        },
        component: () => import('@/pages/system/error/500'),
    },
    {
        path: routePre + '/*',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@/pages/system/error/404'),
    },
];


export default [
    ...frameIn,
    ...frameOuts,
    ...errorPage,
]
import LayoutMain from '@/layout';
import setting from '@/setting';

let routePre = setting.routePre;

const pre = 'setting_';

const meta = {
    auth: true,
};
export default {
    path: routePre + '/settings',
    name: 'setting',
    header: 'setting',
    meta,
    component: LayoutMain,
    children: [
        {
            path: 'user',
            name: `${pre}user`,
            meta: {
                title: '用户管理',
                auth: ['users.store', 'users.update', 'users.destroy'],
            },
            component: () => import('@/pages/setting/user/index'),
        },
        {
            path: 'user/create/:id?',
            name: `${pre}user_create`,
            meta: {
                title: '用户添加',
                activeMenu: routePre + '/settings/user',
                auth: ['users.store', 'users.update']
            },
            component: () => import('@/pages/setting/user/create'),
        },
        {
            path: 'weblogs',
            name: `${pre}weblogs`,
            meta: {
                title: '操作日志',
                auth: true
            },
            component: () => import('@/pages/setting/weblogs/index'),
        },
        {
            path: 'platform',
            name: `${pre}platform`,
            meta: {
                title: '平台管理',
                auth: ['platforms.index']
            },
            component: () => import('@/pages/setting/platform/index'),
        },
        {
            path: 'general',
            name: `${pre}general`,
            meta: {
                title: '通用配置',
                auth: ['systems.setting', 'systems.agreement', 'systems.sms', 'systems.cloud_storage', 'systems.content_safe', 'systems.third_api']
            },
            component: () => import('@/pages/setting/general/index'),
        }, {
            path: 'domains',
            name: `${pre}domain`,
            meta: {
                title: '系统工具',
                auth: ['domains.batch.list', 'domains.check.list', 'domains.upload.file', 'domains.remote.url', 'domains.mysql.optimize']
            },
            component: () => import('@/pages/setting/domain/index'),
        }, {
            path: 'account',
            name: `${pre}account`,
            meta: {
                title: '账户设置',
                auth: true
            },
            component: () => import('@/pages/setting/account/index'),
        },


    ]
}
function includeArray(list1, list2) {

    let status = false;
    if (list1 === true) {
        return true;
    } else {
        if (typeof list2 !== 'object') {
            return false;
        }
        list2.forEach((item) => {
            if (list1 !== undefined) {
                if (list1.includes(item)) status = true;
            }
        });
        return status;
    }
}

export {includeArray};
